import FuseScrollbars from '@fuse/core/FuseScrollbars';
import AppBar from '@material-ui/core/AppBar';
import Hidden from '@material-ui/core/Hidden';
import Icon from '@material-ui/core/Icon';
import SvgIcon from '@material-ui/core/SvgIcon';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Logo from 'app/fuse-layouts/shared-components/Logo';
import NavbarFoldedToggleButton from 'app/fuse-layouts/shared-components/NavbarFoldedToggleButton';
import NavbarMobileToggleButton from 'app/fuse-layouts/shared-components/NavbarMobileToggleButton';
import Navigation from 'app/fuse-layouts/shared-components/Navigation';
import UserNavbarHeader from 'app/fuse-layouts/shared-components/UserNavbarHeader';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles({
  content: {
    overflowX: 'hidden',
    overflowY: 'auto',
    '-webkit-overflow-scrolling': 'touch',
    background:
      'linear-gradient(rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0) 30%), linear-gradient(rgba(0, 0, 0, 0.25) 0, rgba(0, 0, 0, 0) 40%)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 40px, 100% 10px',
    backgroundAttachment: 'local, scroll'
  },
  TopNavbar: {
    backgroundColor: '#FFFFFF !important'
  }
});

function NavbarLayout1(props) {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <div className={clsx('flex flex-col overflow-hidden h-full', props.className)}>
      <AppBar
        position="static"
        elevation={0}
        className={clsx('flex flex-col items-center flex-shrink h-96 md:h-auto px-12', classes.TopNavbar)}
      >
        <div className="self-end">
          <Hidden mdDown>
            <NavbarFoldedToggleButton className="w-40 h-40 p-0 text-black pin_icon">
              <SvgIcon style={{ fontSize: 20 }}>
                <path d="M16,9V4l1,0c0.55,0,1-0.45,1-1v0c0-0.55-0.45-1-1-1H7C6.45,2,6,2.45,6,3v0 c0,0.55,0.45,1,1,1l1,0v5c0,1.66-1.34,3-3,3h0v2h5.97v7l1,1l1-1v-7H19v-2h0C17.34,12,16,10.66,16,9z" />
              </SvgIcon>
            </NavbarFoldedToggleButton>
          </Hidden>

          <Hidden lgUp>
            <NavbarMobileToggleButton className="w-40 h-40 p-0 text-black">
              <Icon>{theme.direction === 'ltr' ? 'arrow_back' : 'arrow_forward'}"</Icon>
            </NavbarMobileToggleButton>
          </Hidden>
        </div>
      </AppBar>

      <FuseScrollbars className={clsx(classes.content)} option={{ suppressScrollX: true }}>
        <UserNavbarHeader />

        <Navigation layout="vertical" />
      </FuseScrollbars>
        <div className="flex flex-1 mx-8 pt-16">
          <Logo />
        </div>
    </div>
  );
}

export default React.memo(NavbarLayout1);
