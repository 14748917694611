import FuseUtils from '@fuse/utils/FuseUtils';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import apiEndpoints from 'app/constants/apiEndpoints';

const { GOOGLE: GOOGLE_ENDPOINT, USERS: USERS_ENDPOINT, USER_DATA, ENCOMPASS_LOGIN: ENCOMPASS_LOGIN_ENDPOINT } = apiEndpoints;

/* eslint-disable camelcase */

class JwtService extends FuseUtils.EventEmitter {
  init() {
    this.setInterceptors();
    this.handleAuthentication();
  }

  setInterceptors = () => {
    axios.interceptors.response.use(
      response => {
        return response;
      },
      err => {
        return new Promise(() => {
          if (err.message === 'Network Error') {
            this.emit('onServerError', 'A Server Error occurred! Please check your Internet Connection.');
          } else if (err.response.status === 401 && err.config && !err.config.__isRetryRequest) {
            // if you ever get an unauthorized response, logout the user
            this.emit('onAutoLogout', err.response.data.error.message);//'The combination of user and password doesn’t exist'
          } else if (err.response.status === 403) {
            this.emit('onAutoLogout', err.response.data.error.message);
          }
          throw err;
        });
      }
    );
  };

  handleAuthentication = () => {
    const access_token = this.getAccessToken();

    if (!access_token) {
      this.emit('onNoAccessToken');
      return;
    }

    if (this.isAuthTokenValid(access_token)) {
      this.setSession(access_token);
      this.emit('onAutoLogin', true);
    } else {
      this.setSession(null);
      this.emit('onAutoLogout', 'Access expired');
    }
  };

  // createUser = data => {
  //   return new Promise((resolve, reject) => {
  //     axios.post('/api/auth/register', data).then(response => {
  //       if (response.data.user) {
  //         this.setSession(response.data.access_token);
  //         resolve(response.data.user);
  //       } else {
  //         reject(response.data.error);
  //       }
  //     });
  //   });
  // };

  signInWithEmailAndPassword = (email, password) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${USERS_ENDPOINT}login`, {
          email,
          password
        })
        .then(response => {
          const { data } = response;
          if (data.data && data.data.user) {
            this.setSession(data.data.token);
            resolve(data.data.user);
          } else {
            reject(data.error);
          }
        });
    });
  };

  signInWithGoogle = (email, familyName, givenName, googleId) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${GOOGLE_ENDPOINT}login`, {
          email,
          familyName,
          givenName,
          googleId
        })
        .then(response => {
          const { data } = response;
          if (data.data && data.data.user) {
            this.setSession(data.data.token);
            resolve(data.data.user);
          } else {
            reject(data.error);
          }
        });
    });
  };


  signInWithEncompassToken = (authorizationCode, redirectUri) => {
    const req = {
      authorizationCode,
      redirectUri
    };
    return new Promise((resolve, reject) => {
      axios.post(`${ENCOMPASS_LOGIN_ENDPOINT}login`, req).then(response => {
        const { data } = response;
        if (data.data && data.data.user) {
          this.setSession(data.data.token);
          resolve(data.data.user);
        } else {
          reject(data.error);
        }
      });
    });
  };

  signInWithToken = () => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${USERS_ENDPOINT}access-token`, {
          token: this.getAccessToken()
        })
        .then(response => {
          if (response.data && response.data.data.user) {
            this.setSession(this.getAccessToken());
            resolve(response.data.data.user);
          } else {
            this.logout();
            reject(new Error('Failed to login with token.'));
          }
        })
        .catch(() => {
          this.logout();
          reject(new Error('Failed to login with token.'));
        });
    });
  };

  updateUserData = user => {
    return axios.post(USER_DATA, {
      user
    });
  };

  setSession = access_token => {
    if (access_token) {
      localStorage.setItem('jwt_access_token', access_token);
      axios.defaults.headers.common.Authorization = `Bearer ${access_token}`;
    } else {
      localStorage.removeItem('jwt_access_token');
      delete axios.defaults.headers.common.Authorization;
    }
  };

  logout = (message = null) => {
    this.setSession(null);
    if(message) this.emit('onUnauthorizedUser', message);
  };

  isAuthTokenValid = access_token => {
    if (!access_token) {
      return false;
    }
    const decoded = jwtDecode(access_token);
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      console.warn('access token expired');
      return false;
    }

    return true;
  };

  getAccessToken = () => {
    return window.localStorage.getItem('jwt_access_token');
  };
}

const instance = new JwtService();

export default instance;
