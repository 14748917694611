import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  root: {},
  headernav: {
    background: '#FFF',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    zIndex: 50

  },
  loginLogo: {
    width: '200px',
  },
  loginIcons: {
    width: '40px',
  },
  hiddenNav: {
    marginTop: '266px',
    paddingLeft: '60px',
    paddingRight: '60px',
    marginLeft: '-36px',
    paddingBottom: '20px',
    paddingTop: '20px',
    borderRight: '1px solid #ccc',
    borderBottom: '1px solid #ccc',
  },
  mapNav: {
    gap: '6.4px',
  },
  alink: {
    fontFamily: 'Gotham',
    fontWeight: '400',
    background: 'white !important',
    color: 'black !important',
    lineHeight: '36px',
    fontSize: '20px',
    borderBottom: 'none !important',
    '&:hover': {
      color: '#ee1c25 !important',
    },
  },
  divider: {
    borderBottom: '1px solid #ccc',
    margin: '5px 0',
  }
}));

const links = [
  {
    name: 'Closing Board',
    customUrl: '/closing-board/calendar',
  },
  // {
  //   name: 'Directory',
  //   customUrl: '/data-directory/lead-sources',
  // },
  {
    name: 'Marketing Hub',
    customUrl: '/',
  },
  {
    name: 'Resource Center',
    customUrl: '/',
  },
  // {
  //   name: 'User Profile',
  //   customUrl: '/settings/users',
  // },
  {
    name: 'Sign Out',
    customUrl: '/logout',
  },
];

const NavbarHome = () => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  return (
    <header className={clsx(classes.headernav, 'lg:pt-3 lg:pb-4 w-full')}>
        <div className="mx-36">
            <div className="flex container items-center justify-between">
                <div 
                  onClick={() => setIsOpen(!isOpen)}
                  className="cursor-pointer outline-none"
                >
                  <img className={clsx(classes.loginIcons)} src='assets/images/icons/Hamburger_menu.svg' alt='logo' />
                </div>
                <div className={clsx(classes.hiddenNav, `bg-white fixed z-20 ${isOpen ? "" : "hidden"} transition-all duration-500`)} >
                  <div className={clsx(classes.mapNav, "flex flex-col")}>
                  {links.map((link, index) => (
                      <>
                        {link.customUrl ? (
                          <a className={clsx(classes.alink)} target="_blank" rel="noopener noreferrer" href={link.customUrl} key={index}>
                            {link.name}
                          </a>
                        ) : null}
                        {link.name === 'Resource Center' && <div className={clsx(classes.divider)} key={`divider-${index}`} />}
                      </>
                    ))}
                  </div>
                </div>
                <div>
                  <Link to="/menu/home-page">
                    <img className='w-136 md:w-200' src='assets/images/logos/icentral-logo.png' alt='logo' />
                  </Link>
                </div>
                <div className='flex gap-16'>
                  <img className='w-24' src='assets/images/icons/FAQ-icon.png' alt='question' />
                  <img className='w-24' src='assets/images/icons/notification.png' alt='bell' />
                </div>
            </div>
        </div>
    </header>
  )
}

export default NavbarHome